import React, { useState } from "react"
import { useEffect } from "react"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import GreenwoodbusBanner1 from "../../../images/uploads/Greenwoodbus/greenwoodbus-banner-1.png"
import GreenwoodbusBanner2 from "../../../images/uploads/Greenwoodbus/greenwoodbus-banner-2.png"
import GreenwoodImg3 from "../../../images/uploads/Greenwoodbus/greenwood-img-3.png"
import GreenwoodImg4 from "../../../images/uploads/Greenwoodbus/greenwood-img-4.png"
import GreenwoodImg5 from "../../../images/uploads/Greenwoodbus/greenwood-img-5.png"
import GreenwoodImg6 from "../../../images/uploads/Greenwoodbus/greenwood-img-6.png"
import GreenwoodImg7 from "../../../images/uploads/Greenwoodbus/greenwood-img-7.svg"
import GreenLogin from "../../../images/uploads/Greenwoodbus/green-login.png"
import GreenRegister from "../../../images/uploads/Greenwoodbus/green-register.png"
import GreenLines from "../../../images/uploads/Greenwoodbus/green-lines.png"
import GreenService from "../../../images/uploads/Greenwoodbus/green-service.png"
import GreenDetails from "../../../images/uploads/Greenwoodbus/green-details.png"
import GreenCard from "../../../images/uploads/Greenwoodbus/green-card.png"
import GreenTicket from "../../../images/uploads/Greenwoodbus/green-ticket.png"


import addcs1 from "../../../images/uploads/decodeup-mahoni-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-flow-additional-case-study.png"

const GreenWoodBus = props => {
  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "greenwoodbus"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />
        {/* banner  section */}
        <section className="h-full xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Schools Bus Passes Management Platform
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  School Bus Pass & ID Cards Management System
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  This client, Greenwood Bus Service, is based in Australia and
                  manages the bus services for several schools. Prior to the
                  company's founding, bus passes were distributed manually.
                  Greenwood established a digital system to move the processes
                  online and make the passes more accessible with less work.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Matthew
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Australia
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, Maintenance and Support, UI/UX Design, Brand
                      strategy, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      PHP, WordPress, Bootstrap, Stripe
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Schools & Institutions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img Section  */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 green-banner-main-img">
          <div className="w-4/5 xl:w-full max-w-10-24 mx-auto">
            <img
              className="mx-auto"
              src={GreenwoodbusBanner1}
              alt="GreenwoodbusBanner1 DecodeUp-Greenwoodbus"
            />
          </div>
          <div className="-mt-90 xl:-mt-4-40 relative z-1">
            <img
              className="w-full mx-auto"
              src={GreenwoodbusBanner2}
              alt="GreenwoodbusBanner2 DecodeUp-Greenwoodbus"
            />
          </div>
        </section>

        {/* Intro Section  */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="pl-0 lg:pl-34 flex flex-col lg:flex-row items-start justify-between gap-4 lg:gap-16">
              <div className="w-full lg:max-w-3-76">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  The general consensus was that Greenwood needed a new site
                  optimized for online booking. But would it fit their budget?
                </h3>
              </div>
              <div className="pl-0 xl:pl-100 flex flex-col gap-y-5 font-worksans-normal text-p4 lg:text-p3 xl:text-p2 text-term-primary">
                <p>
                  They previously used a manual booking process that involved a
                  lot of paperwork, phone calls and more. The process was trying
                  and our client wanted to streamline to make things easier for
                  their team. It was important to allocate buses for schools as
                  needed, so automation and customization were essential to
                  their needs.
                </p>
                <p>
                  With a modest budget and high technological demands our team
                  had their work cut out for them. As we began our initial
                  meetings with the client we spent time learning the current
                  challenges to their business to better understand how to solve
                  them without breaking the bank.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Challenges Section  */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="px-0 lg:px-34">
              <div className="mb-4 lg:mb-6-6">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
              </div>
              <div className="grid md:grid-cols-2 gap-y-5 md:gap-10 lg:gap-20 xl:gap-1-10 font-worksans-normal text-p4 lg:text-p3 xl:text-p2 text-term-primary greenwood-challnges">
                <div className="flex flex-col gap-y-5">
                  <p>
                    We assessed several challenges and drafted a design plan to
                    guide us through the process. After initial consultations we
                    identified the following challenges:
                  </p>
                  <ul className="flex flex-col gap-5 list-none">
                    <li className="ml-6 lg:ml-10 relative">
                      Lack of comprehensive inventory with built-in feature to
                      limit number of passes per family
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Need to college manual data to disperse buses among school
                      systems
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Bus passes needed to be presented by divisions, such as
                      morning passes, evening passes and all day passes
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col gap-y-5">
                  <ul className="flex flex-col gap-5 list-none">
                    <li className="ml-6 lg:ml-10 relative">
                      Color coordinating passes based on school systems
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Establishing and displaying different pricing structures
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Simplifying the front-end for parents to input payment
                      details and child's information
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Parents with multiple children needed an organized way to
                      review all passes
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Digital bus passes that children can show to their driver
                      daily
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Challenges Section images*/}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="grid grid-cols-4 gap-8">
              <div className="col-span-4 md:col-span-3">
                <img
                  className="w-full h-full object-cover xl:object-none"
                  src={GreenwoodImg3}
                  alt="GreenwoodImg3 DecodeUp-Greenwoodbus"
                />
              </div>
              <div className="hidden md:block">
                <img
                  className="w-full h-full object-cover xl:object-none"
                  src={GreenwoodImg4}
                  alt="GreenwoodImg4 DecodeUp-Greenwoodbus"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Solution Section  */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="px-0 lg:px-34">
              <div className="mb-4 lg:mb-6-6">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Solutions
                </h2>
              </div>
              <div className="grid md:grid-cols-2 gap-y-5 md:gap-10 lg:gap-20 xl:gap-1-10 font-worksans-normal text-p4 lg:text-p3 xl:text-p2 text-term-primary greenwood-challnges">
                <div className="flex flex-col gap-y-2 lg:gap-y-6-6">
                  <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    A Website with Answers
                  </h3>
                  <div className="flex flex-col gap-y-5">
                    <p>
                      Although this project presented many challenges, as you
                      saw above, our designers were confident in their ability
                      to execute a plan.
                    </p>
                    <p>
                      DecodeUp designers spend time building a strong plan
                      around the client's needs and pairing modern technology to
                      meet those demands. After creating multiple mockups and
                      meeting with our clients we were able to answer every one
                      of their challenges.
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-y-2 lg:gap-y-6-6">
                  <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Connecting families and bus services on digital pass at a
                    time
                  </h3>
                  <div className="flex flex-col gap-y-5">
                    <p>
                      The site we developed in this case study is fully
                      automated and eases the work of managing digital passes.
                      Data is easily and securely transferred, saving everyone
                      time.
                    </p>
                    <p>
                      The payment portal on the site is also secure and fast
                      with quick transfer of funds and no expensive fees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Solution Section images*/}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full h-full">
              <img
                className="w-full h-full object-cover xl:object-none"
                src={GreenwoodImg5}
                alt="GreenwoodImg5 DecodeUp-Greenwoodbus"
              />
            </div>
          </div>
        </section>

        {/* extra Solution Section  */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="px-0 lg:px-34">
              <div className="mb-2 md:mb-3 lg:mb-4 xl:mb-6-5">
                <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Custom Design That's Future Focused
                </h3>
              </div>
              <div className="mb-50 grid md:grid-cols-2 gap-y-5  md:gap-20 xl-10 lg:gap-1-10 font-worksans-normal text-p4 lg:text-p3 xl:text-p2 text-term-primary greenwood-challnges">
                <p>
                  After reviewing the mockups, we opted to forego drag and drop
                  features and to customize every part of the site to achieve
                  their needs easily and accurately.
                </p>
                <p>
                  It's one of our passions to make sites that are not only
                  dynamic but built to last, regardless of what tech changes
                  happen in the future. Each custom design is reusable so future
                  adjustments are easy to make and won't take up the client's
                  time.
                </p>
              </div>
            </div>
            <div className="w-full h-full">
              <img
                className="w-full h-full object-cover 2xl:object-none"
                src={GreenwoodImg6}
                alt="GreenwoodImg6 DecodeUp-Greenwoodbus"
              />
            </div>
          </div>
        </section>

        {/* Typeface Section  */}
        <section className="green-type-face-sec mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="px-0 py-60 lg:px-34 lg:py-1-20">
              <div className="mb-10">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-dark-blue">
                  Typeface & Colors
                </h2>
              </div>
              <div className="mb-10 lg:mb-20 grid grid-cols-6 gap-5 sm:gap-10 md:gap-90">
                <div className="col-span-6 sm:col-span-2 md:col-span-2">
                  <img
                    className="w-2/5 sm:w-full h-full object-cover sm:object-contain 2xl:object-none"
                    src={GreenwoodImg7}
                    alt="GreenwoodImg7 DecodeUp-Greenwoodbus"
                  />
                </div>
                <div className="green-bus-typo-sec col-span-4 sm:col-span-4 md:col-span-4">
                  <h3>Playfair Display</h3>
                  <h4>ABCDEFGHIJKLMNOPQRSTUVWXYZ</h4>
                  <h5>abcdefghijklmnopqrstuvwxyz</h5>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-0 text-p4 lg:text-p3 xl:text-p2 font-poppings-bold text-term-primary">
                <div className="p-4 lg:p-8 w-full col-span-2 sm:col-span-1 green-colo-box-1">
                  <p>#1A3132</p>
                </div>
                <div className="p-4 lg:p-8 w-full col-span-2 sm:col-span-1 green-colo-box-2">
                  <p>#292929</p>
                </div>
                <div className="p-4 lg:p-8 w-full col-span-2 sm:col-span-1 green-colo-box-3">
                  <p>#EEEDE1</p>
                </div>
                <div className="p-4 lg:p-8 w-full col-span-2 sm:col-span-1 green-colo-box-4">
                  <p>#FFFFFF</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result Section  */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-6 w-full lg:w-5/7 lg:pr-16">
              <h2 className="mb-4 lg:mb-6-6 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="text-p4 lg:text-p3 xl:text-p2 font-worksans-normal text-term-primary">
                As you can see, we were met with a difficult task and came out
                with an effective and attractive solution. We assessed the
                client's goals, the needs of the audience and persevered through
                significant coding labor to present a new product to the world
                that is now saving lives every day.
              </p>
            </div>
            <div className="w-full lg:w-7/10 lg:pr-10">
              <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                Great results mean happy clients. We've love the opportunity to
                make you happy as well.
              </h3>
            </div>
          </div>
        </section>

        {/* Web Image section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="flex flex-col gap-6">
              <div className="flex flex-row gap-5 flex-wrap">
                <div className="w-full h-full md:w-5/48 lg:w-5/20 2xl:w-full 2xl:max-w-5-72 max-h-4-06">
                  <img
                    className="w-full h-full object-cover 2xl:object-none"
                    src={GreenLogin}
                    alt="GreenLogin DecodeUp-Greenwoodbus"
                  />
                </div>
                <div className="w-full h-full md:w-5/48 lg:w-5/20 2xl:w-full 2xl:max-w-5-72 max-h-4-06">
                  <img
                    className="w-full h-full object-cover 2xl:object-none"
                    src={GreenRegister}
                    alt="GreenRegister DecodeUp-Greenwoodbus"
                  />
                </div>
                <div className="hidden lg:block w-full h-full lg:w-0/4 2xl:w-full lg:h-auto lg:max-w-99 max-h-4-06">
                  <img
                    className="w-full h-full object-cover 2xl:object-none"
                    src={GreenLines}
                    alt="GreenLines DecodeUp-Greenwoodbus"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-6 flex-wrap 2xl:flex-nowrap">
                <div className="w-full h-full md:w-5/48 lg:w-11/20 xl:w-1/2 2xl:w-full 2xl:max-w-6-54 2xl:max-h-4-65">
                  <img
                    className="w-full h-full object-cover "
                    src={GreenService}
                    alt="GreenService DecodeUp-Greenwoodbus"
                  />
                </div>
                <div className="flex flex-col gap-6 md:w-5/48 lg:w-21/50 xl:w-47-0 2xl:w-full 2xl:max-w-6-13 h-full 2xl:max-h-4-65">
                  <div className="flex flex-row gap-6 flex-wrap">
                    <div className="w-full mms:w-5/65 md:w-7/61 lg:5/65 xl:w-5/65 2xl:w-full h-full xl:max-w-4-05 max-h-2-89">
                      <img
                        className="w-full h-full object-contain "
                        src={GreenDetails}
                        alt="GreenDetails DecodeUp-Greenwoodbus"
                      />
                    </div>
                    <div className="hidden mms:block w-full mms:w-3/10 md:w-2/8 lg:w-2/8 xl:w-2/29 2xl:w-full h-full lg:max-w-184 max-h-2-89">
                      <img
                        className="w-full h-full object-cover"
                        src={GreenCard}
                        alt="GreenCard DecodeUp-Greenwoodbus"
                      />
                    </div>
                  </div>

                  <div className="w-full h-full lg:max-w-6-13 max-h-151">
                    <img
                      className="w-full h-full object-cover "
                      src={GreenTicket}
                      alt="GreenTicket DecodeUp-Greenwoodbus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}

        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              {/* <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/beauty-products-ecommerce-store"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Beauty Products eCommerce Website
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs1}
                      alt="USA Mahoni Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div> */}
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/virtual-interior-design-lab"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Personalized Virtual Interior Design Lab
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="Flow Lab Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default GreenWoodBus

export const pageQuery = graphql`
query greenwoodbuspagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/school-bus-pass-idcards-management-system.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }     
}

`;